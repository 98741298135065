var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[(!_vm.id || !Object.keys(_vm.editable).includes('name') || _vm.editable.name)?_c('div',[(_vm.id)?_c('p',{staticClass:"mb-4 bg-grey-30 px-4 py-3 text-sm"},[_vm._v(" When you change the details of a contact here, their details will be updated everywhere you have included them in your account. ")]):_vm._e(),_c('h3',{staticClass:"mb-4"},[_vm._v("Full Name")]),_c('v-text-field',_vm._b({attrs:{"placeholder":"Name","error-messages":_vm.showErrors.name && _vm.errorMessages.name ? _vm.errorMessages.name : null},on:{"input":() => {
            _vm.showErrorsSwitch(true, 'name')
            _vm.showErrorsSwitch(false, 'existing')
          },"blur":() => {
            _vm.showErrorsSwitch(true, 'name')
            _vm.showErrorsSwitch(false, 'existing')
          }},model:{value:(_vm.local.name),callback:function ($$v) {_vm.$set(_vm.local, "name", $$v)},expression:"local.name"}},'v-text-field',_vm.textField,false))],1):_vm._e(),(_vm.editable && _vm.editable.address)?_c('div',[(_vm.id && Object.keys(_vm.editable).includes('name') && !_vm.editable.name)?[_c('h2',{staticClass:"mb-4"},[_vm._v("Required fields for "+_vm._s(_vm.local.name))])]:_vm._e(),_c('h3',[_vm._v("Address")]),_c('address-picker',_vm._b({attrs:{"label":"","placeholder":"Address","error-messages":_vm.showErrors.address && _vm.errorMessages.address
            ? _vm.errorMessages.address
            : null},on:{"input":function($event){return _vm.showErrorsSwitch(true, 'address')},"blur":function($event){return _vm.showErrorsSwitch(true, 'address')}},model:{value:(_vm.local.address),callback:function ($$v) {_vm.$set(_vm.local, "address", $$v)},expression:"local.address"}},'address-picker',_vm.textField,false))],2):_vm._e(),(_vm.editable && _vm.editable.phone)?_c('div',[_c('h3',{staticClass:"mb-4"},[_vm._v("Phone number")]),(_vm.showPhoneWarning)?_c('div',{staticClass:"phone-warning"},[_vm._v(" Are you sure this is a valid phone number? ")]):_vm._e(),_c('v-text-field',_vm._b({attrs:{"placeholder":"eg 0400123456","hint":"Mobile or landline with no spaces.","maxlength":"18","error-messages":_vm.showErrors.phone && _vm.errorMessages.phone ? _vm.errorMessages.phone : null},on:{"blur":function($event){_vm.showErrorsSwitch(true, 'phone'),
            (_vm.showPhoneWarning = !_vm.isValidAustralianNumber(_vm.local.phone))}},model:{value:(_vm.local.phone),callback:function ($$v) {_vm.$set(_vm.local, "phone", $$v)},expression:"local.phone"}},'v-text-field',_vm.textField,false))],1):_vm._e(),(_vm.editable && _vm.editable.email)?_c('div',[_c('h3',{staticClass:"mb-4"},[_vm._v("Email address")]),_c('v-text-field',{attrs:{"outlined":"","type":"email","error-messages":_vm.showErrors.email && _vm.errorMessages.email ? _vm.errorMessages.email : null},on:{"input":function($event){return _vm.showErrorsSwitch(true, 'email')},"blur":function($event){return _vm.showErrorsSwitch(true, 'email')}},model:{value:(_vm.local.email),callback:function ($$v) {_vm.$set(_vm.local, "email", $$v)},expression:"local.email"}})],1):_vm._e(),(_vm.editable && _vm.editable.dob)?_c('div',[_c('h3',{staticClass:"mb-4"},[_vm._v("Date of birth")]),_c('date-field',_vm._b({attrs:{"disabled":false,"valid":_vm.dateValid,"not-required":true,"is-adult":_vm.isAdult || _vm.local.isAdult},on:{"update:valid":function($event){_vm.dateValid=$event}}},'date-field',_vm.local.dob,false,true))],1):_vm._e(),(_vm.editable && _vm.editable.occupation)?_c('div',[_c('h3',{staticClass:"mb-4"},[_vm._v("Occupation")]),_c('v-text-field',{attrs:{"outlined":"","type":"text","error-messages":_vm.showErrors.occupation && _vm.errorMessages.occupation
            ? _vm.errorMessages.occupation
            : null},on:{"blur":function($event){_vm.showErrors.occupation = true},"focus":function($event){_vm.showErrors.occupation = false}},model:{value:(_vm.local.occupation),callback:function ($$v) {_vm.$set(_vm.local, "occupation", $$v)},expression:"local.occupation"}})],1):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[(
            _vm.id && Object.keys(_vm.editable).includes('name') && !_vm.editable.name
          )?[_vm._v(" Continue ")]:[_vm._v(" Save ")]],2)],1),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.showErrors.existing && _vm.errorMessages.existing)?_c('div',{staticClass:"align-center red rounded pa-4 mt-4 text-white",domProps:{"innerHTML":_vm._s(_vm.errorMessages.existing)}}):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }